@use "@/styles/variables";

.wrap {
    margin: 10px;
}

.pageHeader {
    margin-top: 0;
    margin-bottom: 0;
}

.section {
    margin-top: 6px;
    background: #f2f2f2;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    &Header {
        font-weight: bold;
        background: white;
        padding: 3px 10px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.12);
    }
}

.participantNum {
    font-weight: bold;
    font-size: 1.5rem;
    margin-right: 10px;
}
