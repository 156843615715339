@use "./fonts";
@use "./variables";

html, body, #root {
    min-height: 100vh;
}

body {
    margin: 0;
    font-family: "SFPro-Regular", sans-serif;
}

#root {
    display: grid;
    grid-template-rows: auto auto 1fr auto;
}