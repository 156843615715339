@font-face {
  font-family: "SFPro-Regular";
  src: local("SFPro-Regular"),
  url("../theme/fonts/SFPro/SFProDisplay-Regular.woff2") format("woff2"),
  url("../theme/fonts/SFPro/SFProDisplay-Regular.woff") format("woff"),
  url("../theme/fonts/SFPro/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFPro-Medium";
  src: local("SFPro-Medium"),
  url("../theme/fonts/SFPro/SFProDisplay-Medium.woff2") format("woff2"),
  url("../theme/fonts/SFPro/SFProDisplay-Medium.woff") format("woff"),
  url("../theme/fonts/SFPro/SFProDisplay-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFPro-Bold";
  src: local("SFPro-Bold"),
  url("../theme/fonts/SFPro/SFProDisplay-Bold.woff2") format("woff2"),
  url("../theme/fonts/SFPro/SFProDisplay-Bold.woff") format("woff"),
  url("../theme/fonts/SFPro/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Times New Roman Regular";
  src: local("Times New Roman"),
  url("../theme/fonts/TimesNewRoman/times_new_roman.woff2") format("woff2"),
  url("../theme/fonts/TimesNewRoman/times_new_roman.woff") format("woff"),
  url("../theme/fonts/TimesNewRoman/times_new_roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Times New Roman Bold";
  src: local("Times New Roman Bold"),
  url("../theme/fonts/TimesNewRoman/times_new_roman_bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}