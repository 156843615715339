$sm-screen: 600px;
$md-screen: 900px;
$lg-screen: 1200px;
$xl-screen: 1536px;

$minOffset: 5px;
$regularOffset: 15px;

$transitionTime: 0.3s;

$primaryColor: #307fa0;
$primaryBackground: transparentize($primaryColor, 0.85);
$dark: black;
$successColor: #36DB5A;
$successBackground: rgba(54, 219, 90, 0.15);

$palette-success-main: #2e7d32;
$palette-error: #d32f2f;

$smallBorderRadius: 4px;
$dangerColor: #DA615C;
$dangerBackground: rgba(218, 97, 92, 0.15);

$secondFont: "Times New Roman Regular", serif;